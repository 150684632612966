

$(document).ready(function() {
	$('.underAll').addClass('allSelected');
  $('#allBox').addClass('allHighlighted');
});


/////////   HAMBURGER MENU ANIMATION FOR DROPDOWN MENU    /////////////

$( ".menuContainer" ).on( "mouseenter", function() {
  $('.menuLine').addClass('menuLineBlue');
});

$( ".menuContainer" ).on( "mouseleave", function() {
  $('.menuLine').removeClass('menuLineBlue');
});


function showMenu () {
  $('.topLine').toggleClass('topClose');
  $('.midLine').toggleClass('midClose');
  $('.midLine2').toggleClass('midClose2');
  $('.bottomLine').toggleClass('bottomClose');
  $('.menuContainer').toggleClass('menuCircle');
  $('.dropDownContainer').toggleClass('expand');
  $('.underDrop').toggleClass('expand');
  $('.dropDownLink').toggleClass('expand');
};


// /////////   FILTER BY PROJECT TYPE     /////////////

function showAll () {
  $('.underAll').addClass('allSelected');
  $('#allBox').addClass('allHighlighted');
  $('.underDev').removeClass('devSelected');
  $('#devBox').removeClass('devHighlighted');
  $('.underDesign').removeClass('designSelected');
  $('#designBox').removeClass('designHighlighted');

  $('.devProject').removeClass('hideDevProject');
  $('.devLabel').removeClass('hideDevLabel');
  $('.designProject').removeClass('hideDesignProject');
  $('.designLabel').removeClass('hideDesignLabel');
  // $('.musicProject').removeClass('hideMusicProject');
  // $('.musicLabel').removeClass('hideMusicLabel');

  $('.projectSix').removeClass('nudgeLeft');
  $('.projectEight').removeClass('makeFullSize');
  $('.projectTen').removeClass('small');
  $('.projectTen').removeClass('nudgeLeft');
  $('.projectTwelve').removeClass('nudgeLeft');
  $('.projectEleven').removeClass('makeFullSize');
  $('.projectThirteen').removeClass('small');
  // $('.projectFourteen').removeClass('makeFullSize');

};


function showDesign () {
  $('.underDesign').addClass('designSelected');
  $('#designBox').addClass('designHighlighted');
  $('.underDev').removeClass('devSelected');
  $('#devBox').removeClass('devHighlighted');
  $('.underAll').removeClass('allSelected');
  $('#allBox').removeClass('allHighlighted');

  $('.devProject').addClass('hideDevProject');
  $('.devLabel').addClass('hideDevLabel');
  $('.designProject').removeClass('hideDesignProject');
  $('.designLabel').removeClass('hideDesignLabel');

  $('.projectSix').addClass('nudgeLeft');
  $('.projectEight').addClass('makeFullSize');
  $('.projectTen').addClass('small');
  $('.projectTen').addClass('nudgeLeft');
  $('.projectEleven').addClass('makeFullSize');
  $('.projectThirteen').addClass('small');
  
  $('.projectTwelve').removeClass('nudgeLeft');

  // $('.musicProject').addClass('hideMusicProject');
  // $('.musicLabel').addClass('hideMusicLabel');
};





function showDev () {
  $('.underDev').addClass('devSelected');
  $('#devBox').addClass('devHighlighted');
  $('.underDesign').removeClass('designSelected');
  $('#designBox').removeClass('designHighlighted');
  $('.underAll').removeClass('allSelected');
  $('#allBox').removeClass('allHighlighted');

  $('.devProject').removeClass('hideDevProject');
  $('.devLabel').removeClass('hideDevLabel');
  $('.designProject').addClass('hideDesignProject');
  $('.designLabel').addClass('hideDesignLabel');


  $('.projectSix').removeClass('nudgeLeft');
  $('.projectEight').removeClass('makeFullSize');
  $('.projectTen').removeClass('small');
  $('.projectTen').removeClass('nudgeLeft');

  $('.projectTwelve').addClass('nudgeLeft');
  // $('.projectFourteen').addClass('makeFullSize');

  // $('.musicProject').addClass('hideMusicProject');
  // $('.musicLabel').addClass('hideMusicLabel');
};



$( ".menuContainer" ).on( "click", function() {
  showMenu();
});

$( "#allBox" ).on( "click", function() {
  showAll();
});

$( "#designBox" ).on( "click", function() {
  showDesign();
});

$( "#devBox" ).on( "click", function() {
  showDev();
});



console.log("Thanks for visiting Screen Theory");
































